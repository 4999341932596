import React from "react";
import { Route, Routes } from "react-router-dom";
import { Home, Portfolio, Resume, ResumePrint, Contact } from "../views";

const Router = () => (
	<div>
		<Routes>
			<Route exact path="/" element={<Home />} />
			<Route exact path="/portfolio" element={<Portfolio />} />
			<Route exact path="/resume" element={<Resume />} />
			<Route exact path="/resumePrint" element={<ResumePrint />} />
			<Route exact path="/contact" element={<Contact />} />
		</Routes>
	</div>
);
export default Router;
