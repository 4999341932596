import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import imageAvatar from '../../assets/images/avatar-images/avtar-image-kevin-kelly.jpg';

export default () => {

	const navbarRef = React.useRef(null)
	const [isToggle, setIsToggle] = useState(false);

	const onNavbarHamburger = () => {
		if (navbarRef.current.getAttribute('aria-expanded') === 'true') {
			navbarRef.current.click();
		}
	}

	const onToggle = () => {
		if (isToggle) {
			document.body.classList.remove('dark-mode');
			setIsToggle(false);
			return
		}
		document.body.classList.add('dark-mode');
		setIsToggle(true);
		return;
	}

	const isTabActive = (path) => {
		if (window.location.pathname === path) {
			return "nav-item active";
		}
		return "nav-item";
	}

	return (
		<header className="header text-center">
			<div className="force-overflow">
				<h1 className="blog-name pt-lg-4 mb-0"><a href="/">Kevin Kelly</a></h1>

				<nav className="navbar navbar-expand-lg navbar-dark">
					<button ref={navbarRef} className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation"
						aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
						<span className="navbar-toggler-icon"></span>
					</button>

					<div id="navigation" className="collapse navbar-collapse flex-column">
						<div className="profile-section pt-3 pt-lg-0">
							<img className="profile-image mb-3 rounded-circle mx-auto"
								src={imageAvatar} alt="image" />

							<div className="bio mb-3">The key to success is consistency</div>
							{/* <!--//bio--> */}
							<ul className="social-list list-inline py-2 mx-auto">
								{/* <li className="list-inline-item"><a href="https://twitter.com/7hekevshow"><i
									className="fab fa-twitter fa-fw"></i></a></li> */}
								<li className="list-inline-item"><a href="https://www.linkedin.com/in/kevin-kelly-01507191/"><i
									className="fab fa-linkedin-in fa-fw"></i></a></li>
								<li className="list-inline-item"><a href="https://github.com/thekevshow"><i
									className="fab fa-github-alt fa-fw"></i></a></li>
								<li className="list-inline-item"><a href="https://stackoverflow.com/users/1352752/thekevshow"><i
									className="fab fa-stack-overflow fa-fw"></i></a></li>
								<li className="list-inline-item"><a className="instagram"
									href="https://instagram.com/kev.show"><i className="fab fa-instagram fa-fw fa-lg"></i></a></li>
								{/* <!-- <li className="list-inline-item"><a href="#"><i className="fab fa-codepen fa-fw"></i></a></li> --> */}
							</ul>
							{/* <!--//social-list--> */}
							<hr />
						</div>
						{/* <!--//profile-section--> */}

						{/* TODO: render by array values generated */}
						<ul className="navbar-nav flex-column text-left">
							<NavLink
								onClick={() => { onNavbarHamburger() }}
								to="/"
								className={() => isTabActive('/')}
							>
								<div className="nav-link" href="/"><i className="fas fa-user fa-fw mr-2"></i>About Me<span
									className="sr-only">(current)</span></div>
							</NavLink>
							<NavLink
								onClick={() => { onNavbarHamburger() }}
								to="/portfolio"
								className={() => isTabActive('/portfolio')}
							>
								<div className="nav-link" href="/portfolio" onClick={() => { }}><i
									className="fas fa-laptop-code fa-fw mr-2"></i>Portfolio</div>
							</NavLink>
							{/* <!-- <li className="nav-item">
								<a className="nav-link" href="services.html"><i className="fas fa-briefcase fa-fw mr-2"></i>Services
									&amp; Pricing</a>
							</li> --> */}
							<NavLink
								onClick={() => { onNavbarHamburger() }}
								to="/resume"
								className={() => isTabActive('/resume')}
							>
								<div className="nav-link" href="/resume"><i className="fas fa-file-alt fa-fw mr-2"></i>Resume</div>
							</NavLink>
							{/* <!-- <li className="nav-item">
								<a className="nav-link" href="blog-home.html"><i className="fas fa-blog fa-fw mr-2"></i>Blog</a>
							</li> --> */}
							<NavLink
								onClick={() => { onNavbarHamburger() }}
								to="/contact"
								className={() => isTabActive('/contact')}
							>
								<div className="nav-link" href="/contact"><i className="fas fa-envelope-open-text fa-fw mr-2"></i>Contact</div>
							</NavLink>
							{/* <!-- <li className="nav-item dropdown">
							<a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<i className="fas fa-cogs fa-fw mr-2"></i>More Pages
							</a>
							<div className="dropdown-menu" aria-labelledby="navbarDropdown">
								<a className="dropdown-item" href="project.html">Project Page</a>
								<a className="dropdown-item" href="blog-home.html">Blog Home 1</a>
								<a className="dropdown-item" href="blog-home-alt.html">Blog Home 2</a>
								<a className="dropdown-item" href="blog-post.html">Blog Post</a>
							</div>
						</li> --> */}
						</ul>

						{/* <div className="my-2">
							<a className="btn btn-primary" href="contact" target="_blank"><i
								className="fas fa-paper-plane mr-2"></i>Hire Me</a>
						</div> */}

						<div className="dark-mode-toggle text-center w-100">
							<hr className="mb-4" />
							<h4 className="toggle-name mb-3 "><i className="fas fa-adjust mr-1"></i>Dark Mode</h4>

							<input className="toggle" id="darkmode" type="checkbox" onClick={() => { return onToggle() }} />
							<label className="toggle-btn mx-auto mb-0" for="darkmode"></label>

						</div>
						{/* <!--//dark-mode-toggle--> */}
					</div>
				</nav>
			</div>
			{/* <!--//force-overflow--> */}
		</header>
	);
}
