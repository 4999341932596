import React from "react";

const Footer = () => (
	<div className="main-wrapper">
		<footer
			className="footer"
			style={{
				alignContent: "center",
				alignItems: "center",
				alignSelf: "center",
				// background: "white",
				// background: "#AFDBD8"
			}}
		>
			<div className="footer text-center py-4">
				<p>
					{" "}
					Powered By <strong className="is-primary-txt">thekevshow.com</strong> <br />
					Copyright {new Date().getFullYear()} <br />
				</p>
			</div>
		</footer>
	</div>
);

export default Footer;
