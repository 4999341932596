import React from "react";
import EWAGER_PROJECT_IMAGE from '../../../../assets/images/project/ewager-project-image.png';

export const EwagerProject = ({ }) => {
    return (<div className="isotope-item col-md-6 mb-5 webapp frontend mobileapp backend">
        <div className="card project-card">
            <div className="row no-gutters">
                <div className="col-lg-4 card-img-holder">
                    <img src={EWAGER_PROJECT_IMAGE} className="card-img" alt="image" />
                </div>
                <div className="col-lg-8">
                    <div className="card-body">
                        <h5 className="card-title"><a href="project" className="theme-link">Ewager Betting Service</a></h5>
                        <p className="card-text">Created an Esports betting web app and mobile app, reactjs and react native respectfully. This application supported betting on
                            esports tournaments and more live based games in real time with cryptocurrency. </p>
                        <p className="card-text"><small className="text-muted">Client: Ewager (self)</small></p>
                    </div>
                </div>
            </div>
            <div className="link-mask">
                <a className="link-mask-link" href="project"></a>
                <div className="link-mask-text">
                    <a className="btn btn-secondary" href="project">
                        <i className="fas fa-eye mr-2"></i>View Case Study
                    </a>
                </div>
            </div>
            {/* <!--//link-mask--> */}
        </div>
        {/* <!--//card--> */}
    </div>)
}