import React from "react";
import resumeAvatar from '../../../../assets/images/avatar-images/avtar-image-kevin-kelly.jpg';
const DATE_PROGRAMMING = new Date("01/01/2012");

export const ResumeComponent = () => {
    const getYearsExperience = (date1 = new Date(), date2) => {
        const diff = Math.floor(date1.getTime() - date2.getTime());
        const day = 1000 * 60 * 60 * 24;
        const days = Math.floor(diff / day);
        const months = Math.floor(days / 31);
        const years = Math.floor(months / 12);
        return years;
    }
    return (<div className="container px-3 px-lg-5">
        {/* removed marging bottom here in class --> mb-5 my-5, added top margin 3rem (to compensate)*/}
        <article className="resume-wrapper mx-auto theme-bg-light p-5 shadow-lg" style={{ marginTop: "3rem" }}>
            <div className="resume-header">
                <div className="row align-items-center">
                    <div className="resume-title col-12 col-md-6 col-lg-8 col-xl-9">
                        <h2 className="resume-name mb-0 text-uppercase">Kevin Kelly</h2>
                        {/* <div className="resume-tagline mb-3 mb-md-0">CEO / Engineer</div> */}
                    </div>
                    {/* <!--//resume-title--> */}
                    <div className="resume-contact col-12 col-md-6 col-lg-4 col-xl-3">
                        <ul className="list-unstyled mb-0">
                            <li className="mb-2"><i className="fas fa-phone-square fa-fw fa-lg mr-2 "></i><a className="resume-link" href="tel:7164818619">716-481-8619</a></li>
                            <li className="mb-2"><i className="fas fa-envelope-square fa-fw fa-lg mr-2"></i><a className="resume-link" href="mailto:kev2316@gmail.com">kev2316@gmail.com</a></li>
                            <li className="mb-2"><i className="fas fa-globe fa-fw fa-lg mr-2"></i><a className="resume-link" href="https://thekevshow.com">https://thekevshow.com</a></li>
                            <li className="mb-0"><i className="fas fa-map-marker-alt fa-fw fa-lg mr-2"></i>Austin, TX</li>
                        </ul>
                    </div>
                    {/* <!--//resume-contact--> */}
                </div>
                {/* <!--//row--> */}

            </div>
            {/* <!--//resume-header--> */}
            <hr />
            <div className="resume-intro py-3">
                <div className="media flex-column flex-md-row align-items-center">
                    <img className="resume-profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto" src={resumeAvatar} alt="image" />
                    <div className="media-body text-left">
                        <p className="mb-0">A Senior Engineer and Leader with {getYearsExperience(undefined, DATE_PROGRAMMING)}+ years of
                            experience responsible for full-stack architecture and development. I am an enthusiastic technologist, proven leader,
                            and convey excellent communication skills. I am seeking a leadership position with a progressive employer where
                            I can contribute to developing new technologies and work with bright, dedicated individuals.
                        </p>
                    </div>
                    {/* <!--//media-body--> */}
                </div>
            </div>
            {/* <!--//resume-intro--> */}
            <hr />
            <div className="resume-body">
                <div className="row">
                    <div className="resume-main col-12 col-lg-8 col-xl-9 pr-0 pr-lg-5">
                        <section className="work-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Work Experiences</h3>
                            <div className="item mb-3">
                                <div className="item-heading row align-items-center mb-2">
                                    <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Director of Software Engineering</h4>
                                    <div className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">Centivo | 2019 - Present</div>
                                </div>
                                <div className="item-content">
                                    <p>Being a startup, many hats and roles where held within the title. From Architecturing  to designing our infrastructure,
                                        developing large portion of our code before gaining a larger team. Then providing team leadership all the way up to the department level of management. </p>
                                    <ul className="resume-list">
                                        <li>Train and mentor junior developers and engineers, teaching skills in cloud
                                            computing platform development, along with web app FE development and
                                            working to improve overall team performance.</li>
                                        <li>Worked with project managers, developers, quality assurance and customers to
                                            resolve technical issues.</li>
                                        <li>Created code first infrastructure AWS cloud deployments. This includes
                                            extensive use with their CDK framework.</li>
                                        <li>Lead main rewrite of several systems within the existing platform to fix
                                            performance issues, overall data integrity issues and scalability concerns.</li>
                                        <li>Found ways to save the company money by making sure everything was
                                            serverless that could be, and fixed non performant services that were eating costs
                                            because of there improper implementation.</li>
                                        <li>Developed and managed 5 key services that integrate with our entire company
                                            platform offerings.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--//item--> */}
                            <div className="item mb-3">
                                <div className="item-heading row align-items-center mb-2">
                                    <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Software Engineer / Lead</h4>
                                    <div className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">Willis Towers Watson | 2015 - 2019</div>
                                </div>
                                <div className="item-content">
                                    <p>Helped manage an on-prem software platform, that we moved from legacy based designs to modern technology and Azure cloud services over time.
                                        Throughout this role towards the end, I lead a small team as we engaged taking over multiple services to enhance.</p>
                                    <ul className="resume-list">
                                        <li>Wrote maintainable and extensible code in a team environment.</li>
                                        <li>Modify existing software to correct errors, upgrade interfaces and improve
                                            performance, implement new frameworks and new .net technologies.</li>
                                        <li>Use new technologies to improve overall goals of efficiency and dependency
                                            management including .net core to replace .net standard framework. Also
                                            implemented docker containers with use of .net core as well.</li>
                                        <li>Consult regularly with customers on project status, proposals and technical
                                            issues.</li>
                                        <li>Rapidly prototype new capabilities to confirm feasibility.</li>
                                        <li>Research, design and implement scalable applications for information
                                            identification, extraction, analysis, retrieval and indexing.</li>
                                        <li>Work closely with other team members to plan, design and develop robust
                                            solutions in a timely manner.</li>
                                        <li>Interface with business analysts, developers and technical support to determine
                                            best requirement specifications.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--//item--> */}
                            <div className="item mb-3">
                                <div className="item-heading row align-items-center mb-2">
                                    <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Co-Founder & CTO</h4>
                                    <div className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">Socievo LLC | 2014 - 2016</div>

                                </div>
                                <div className="item-content">
                                    <p>I was part of a co-authored an innovative nightlife application, which involved the entire full-stack of the development process,
                                        along with everything else under the sun from marketing and more.</p>
                                    <ul className="resume-list">
                                        <li>Developed and launched mobile IOS and Android applications.</li>
                                        <li>Use of Azure deployment technologies, along with self hosted environment deployments.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--//item--> */}
                            <div className="item">
                                <div className="item-heading row align-items-center mb-2">
                                    <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Profession IT & Web Services</h4>
                                    <div className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">Self-Employed | 2008 - 2015</div>

                                </div>
                                <div className="item-content">
                                    {/* <p>Role description goes here</p> */}
                                    <ul className="resume-list">
                                        <li>Built and managed several websites for employers such as (EBC Inc. of Buffalo)
                                            an insurance, payroll, and HR company.</li>
                                        <li>Executed various projects and personal work items for multiple individuals and small companies.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--//item--> */}
                            <div className="item">
                                <div className="item-heading row align-items-center mb-2">
                                    <h4 className="item-title col-12 col-md-6 col-lg-8 mb-2 mb-md-0">Web Developer</h4>
                                    <div className="item-meta col-12 col-md-6 col-lg-4 text-muted text-left text-md-right">SUNY Brockport | 2011 - 2015</div>
                                </div>
                                <div className="item-content">
                                    {/* <p>Role description goes here</p> */}
                                    <ul className="resume-list">
                                        <li>LAMP stack development involving the campus web servers and applications.</li>
                                        <li>Followed best practice designs and helped with beefing our security practices as
                                            well.</li>
                                    </ul>
                                </div>
                            </div>
                            {/* <!--//item--> */}

                        </section>
                        {/* <!--//work-section--> */}

                        {/* <ProjectSectionComponent/> */}

                    </div>
                    {/* <!--//resume-main--> */}
                    <aside className="resume-aside col-12 col-lg-4 col-xl-3 px-lg-4 pb-lg-4">
                        <section className="skills-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Skills</h3>
                            <div className="item">
                                <h4 className="item-title">Technical</h4>
                                <ul className="list-unstyled resume-skills-list">
                                    <li className="mb-2">Java/Java EE/Android API</li>
                                    <li className="mb-2">Objective C/Swift</li>
                                    <li className="mb-2">C/C++/C#</li>
                                    <li className="mb-2">JavaScript/React/Vue</li>
                                    <li className="mb-2">XC Mobile: PWA's, Ionic & React Native</li>
                                    <li className="mb-2">Python/PHP</li>
                                    <li className="mb-2">Node.js/ASP.NET</li>
                                    <li className="mb-2">Database design (Relational & Document Based)</li>
                                    <li className="mb-2">Postgres/MySQL/SQL & DyanmoDB/CosmosDB/MongoDB...</li>
                                    <li className="mb-2">Object-oriented design</li>
                                    <li className="mb-2">Cloud Services AWS/Azure etc.</li>
                                    <li className="mb-2">ML Frameworks: TF, DL4j</li>
                                    <li>Devops: CI/CD etc.</li>
                                </ul>
                            </div>
                            {/* <!--//item--> */}
                            <div className="item">
                                <h4 className="item-title">Professional</h4>
                                <ul className="list-unstyled resume-skills-list">
                                    <li className="mb-2">Effective communication</li>
                                    <li className="mb-2">Team player</li>
                                    <li className="mb-2">Strong problem solver</li>
                                    <li className="mb-2">Gets things done</li>
                                    <li>Good time management</li>
                                </ul>
                            </div>
                            {/* <!--//item--> */}
                        </section>
                        {/* <!--//skills-section--> */}
                        <section className="education-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Education</h3>
                            <ul className="list-unstyled resume-education-list">
                                <li className="mb-3">
                                    <div className="resume-degree font-weight-bold">B.A. Computer Science - AC Track</div>
                                    <div className="resume-degree-org text-muted">The College at Brockport</div>
                                    <div className="resume-degree-time text-muted">2010 - 2015</div>
                                </li>
                                {/* <li>
                                    <div className="resume-degree font-weight-bold">BSc Maths and Physics</div>
                                    <div className="resume-degree-org text-muted">Imperial College London</div>
                                    <div className="resume-degree-time text-muted">2007 - 2010</div>
                                </li> */}
                            </ul>
                        </section>
                        {/* <!--//education-section--> */}
                        {/* <section className="education-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Awards</h3>
                            <ul className="list-unstyled resume-awards-list">
                                <li className="mb-3">
                                    <div className="font-weight-bold">Award Lorem Ipsum</div>
                                    <div className="text-muted">Microsoft lorem ipsum (2019)</div>
                                </li>
                                <li>
                                    <div className="font-weight-bold">Award Donec Sodales</div>
                                    <div className="text-muted">Oracle Aenean (2017)</div>
                                </li>
                            </ul>
                        </section> */}
                        {/* <!--//education-section--> */}
                        <section className="skills-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Languages</h3>
                            <ul className="list-unstyled resume-lang-list">
                                {/* <li className="mb-2">English <span className="text-muted">(Native)</span></li> */}
                                {/* <li>Spanish <span className="text-muted">(Professional)</span></li> */}
                                <li>English <span className="text-muted">(Native)</span></li>
                            </ul>
                        </section>
                        {/* <!--//certificates-section--> */}
                        <section className="skills-section py-3">
                            <h3 className="text-uppercase resume-section-heading mb-4">Interests</h3>
                            <ul className="list-unstyled resume-interests-list mb-0">
                                <li className="mb-2">Working Out</li>
                                <li className="mb-2">Gaming</li>
                                <li className="mb-2">Coding / Development</li>
                                <li className="mb-2">Hockey / Rec Sports</li>
                                <li>Travelling</li>
                            </ul>
                        </section>
                        {/* <!--//certificates-section--> */}

                    </aside>
                    {/* <!--//resume-aside--> */}
                </div>
                {/* <!--//row--> */}
            </div>
            {/* <!--//resume-body--> */}
            <hr />
            <div className="resume-footer text-center">
                <ul className="resume-social-list list-inline mx-auto mb-0 d-inline-block text-muted">
                    <li className="list-inline-item mb-lg-0 mr-3"><a className="resume-link" href="https://github.com/thekevshow"><i className="fab fa-github-square fa-2x mr-2" data-fa-transform="down-4"></i><span className="d-none d-lg-inline-block text-muted">github.com/thekevshow</span></a></li>
                    <li className="list-inline-item mb-lg-0 mr-3"><a className="resume-link" href="https://www.linkedin.com/in/kevin-kelly-01507191/"><i className="fab fa-linkedin fa-2x mr-2" data-fa-transform="down-4"></i><span className="d-none d-lg-inline-block text-muted">linkedin.com/in/kevin-kelly-01507191/</span></a></li>
                    {/* <li className="list-inline-item mb-lg-0 mr-lg-3"><a className="resume-link" href="https://twitter.com/7hekevshow"><i className="fab fa-twitter-square fa-2x mr-2" data-fa-transform="down-4"></i><span className="d-none d-lg-inline-block text-muted">@7hekevshow</span></a></li> */}
                </ul>
            </div>
            {/* <!--//resume-footer--> */}
        </article>
        {/* <!--//container--> */}
    </div>);
};
