import React from "react";
import NIGHTLI_PROJECT_IMAGE from '../../../../assets/images/project/nightli-project-image_auto_x2.jpg';

export const NightliProject = ({ }) => {
    return (<div className="isotope-item col-md-6 mb-5 mobileapp backend frontend">
        <div className="card project-card">
            <div className="row no-gutters">
                <div className="col-lg-4 card-img-holder">
                    <img src={NIGHTLI_PROJECT_IMAGE} className="card-img" alt="image" />
                </div>
                <div className="col-lg-8">
                    <div className="card-body">
                        <h5 className="card-title"><a href="project" className="theme-link">Nightli Application</a></h5>
                        <p className="card-text">In college one of the first projects that I started was a company based LLC project.
                            An Android Native and IOS Native mobile app for discovering bar activity around you and more. We also had a web page mainly for
                            listing out the experience but even as long ago as this was, we were already focusing on web app experience as well, which there was
                            some functionality for, using java as a backend, particualrly glassfish, which also hosted the website within an Azure environment.</p>
                        <p className="card-text"><small className="text-muted">Client: Socievo LLC (Self)</small></p>
                    </div>
                </div>
            </div>
            <div className="link-mask">
                <a className="link-mask-link" href="project"></a>
                <div className="link-mask-text">
                    <a className="btn btn-secondary" href="project">
                        <i className="fas fa-eye mr-2"></i>View Case Study
                    </a>
                </div>
            </div>
            {/* <!--//link-mask--> */}
        </div>
        {/* <!--//card--> */}
    </div>)
}