import React from "react";
import THREEBROTHERSPAINT_PROJECT_IMAGE from '../../../../assets/images/project/3brotherspaint-project-image.png';

export const ThreeBrothersPaintProject = ({ }) => {
    return (<div className="isotope-item col-md-6 mb-5 webapp frontend">
        <div className="card project-card">
            <div className="row no-gutters">
                <div className="col-lg-4 card-img-holder">
                    <img src={THREEBROTHERSPAINT_PROJECT_IMAGE} className="card-img" alt="image" />
                </div>
                <div className="col-lg-8">
                    <div className="card-body">
                        <h5 className="card-title"><a href="project" className="theme-link">3BrothersPaint Small Contact Page</a></h5>
                        <p className="card-text">Helped setup a small marketing contact page for a paint company that wanted it to look similar to their trash site.
                            Although the request was small, I hosted it in AWS to keep the cost down to a minimum along with free contact forum messaging.</p>
                        <p className="card-text"><small className="text-muted">Client: 3BrothersPaint</small></p>
                    </div>
                </div>
            </div>
            <div className="link-mask">
                <a className="link-mask-link" href="project"></a>
                <div className="link-mask-text">
                    <a className="btn btn-secondary" href="project">
                        <i className="fas fa-eye mr-2"></i>View Case Study
                    </a>
                </div>
            </div>
            {/* <!--//link-mask--> */}
        </div>
        {/* <!--//card--> */}
    </div>)
}