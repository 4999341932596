import React from "react";
import Isotope from 'isotope-layout';
import { NightliProject, EwagerProject, ThreeBrothersPaintProject, GreenViewTeamProject } from "../../shared/components";

export default () => {

    // init one ref to store the future isotope object
    const isotope = React.useRef()
    // store the filter keyword in a state
    const [filterKey, setFilterKey] = React.useState('*')

    // initialize an Isotope object with configs
    React.useEffect(() => {
        isotope.current = new Isotope('.isotope', {
            itemSelector: '.isotope-item',
            layoutMode: 'fitRows',
        })
        // cleanup
        return () => isotope.current.destroy()
    }, [])

    // handling filter key change
    React.useEffect(() => {
        filterKey === '*'
            ? isotope.current.arrange({ filter: `*` })
            : isotope.current.arrange({ filter: `.${filterKey}` })
    }, [filterKey])

    const handleFilterKeyChange = key => () => { return setFilterKey(key) }

    return (<div className="main-wrapper">
        <section className="cta-section theme-bg-light py-5">
            <div className="container text-center single-col-max-width">
                <h2 className="heading">Portfolio</h2>
                <div className="intro">
                    <p>Welcome to my online portfolio. I own a company called Ucept LLC and we can arrange getting the work you need done.
                        Want some help building your software?</p>
                </div>
                <a className="btn btn-primary" href="contact" target="_blank"><i className="fas fa-paper-plane mr-2"></i>Hire Me</a>

            </div>
            {/* <!--//container--> */}
        </section>
        <section className="projects-list px-3 py-5 p-md-5">
            <div className="container">
                <div className="text-center">
                    <ul id="filters" className="filters mb-5 mx-auto pl-0">
                        <li className={`type  mb-3 mb-lg-0 ${filterKey === '*' ? 'active' : ''}`} onClick={handleFilterKeyChange('*')}>All</li>
                        <li className={`type  mb-3 mb-lg-0 ${filterKey === 'webapp' ? 'active' : ''}`} onClick={handleFilterKeyChange('webapp')}>Web App</li>
                        <li className={`type  mb-3 mb-lg-0 ${filterKey === 'mobileapp' ? 'active' : ''}`} onClick={handleFilterKeyChange('mobileapp')}>Mobile App</li>
                        <li className={`type  mb-3 mb-lg-0 ${filterKey === 'frontend' ? 'active' : ''}`} onClick={handleFilterKeyChange('frontend')}>Frontend</li>
                        <li className={`type  mb-3 mb-lg-0 ${filterKey === 'backend' ? 'active' : ''}`} onClick={handleFilterKeyChange('backend')}>Backend</li>
                    </ul>
                    {/* <!--//filters--> */}
                </div>

                <div className="project-cards row isotope">
                    <EwagerProject />
                    <NightliProject />
                    <ThreeBrothersPaintProject />
                    <GreenViewTeamProject />
                </div>
                {/* <!--//row--> */}
            </div>
        </section>
    </div>
    );
};