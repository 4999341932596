import React from "react";
import { NightliProject, EwagerProject, ThreeBrothersPaintProject, GreenViewTeamProject } from "../../../shared/components";

export const Featured = ({ }) => {
    return (
        <section className="featured-section p-3 p-lg-5">
            <div className="container">
                <h2 className="section-title font-weight-bold mb-5">Featured Projects</h2>
                <div className="row">
                    <EwagerProject />
                    <NightliProject />
                    <ThreeBrothersPaintProject />
                    <GreenViewTeamProject />
                </div>
                {/* <!--//row--> */}
                <div className="text-center py-3"><a href="portfolio" className="btn btn-primary"><i
                    className="fas fa-arrow-alt-circle-right mr-2"></i>View Portfolio</a></div>

            </div>
            {/* <!--//container--> */}
        </section>
    )
};