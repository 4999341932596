import React from 'react';
import $ from 'jquery';
import { ResumeComponent } from '../components/ResumeComponent'

export default () => {
    $(function () {
        $("header, footer").hide();
    });
    return (
        <div>
            <div style={{ marginLeft: '0px' }} className="main-wrapper" >
                <ResumeComponent />
            </div>
        </div>);
};
