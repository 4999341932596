import React from 'react';
import { ResumeComponent } from "./components/ResumeComponent";
import RESUME_FILE_DOWNLOAD from '../../../assets/docs/generated-resume.pdf'

export default () => {
    return (
        <div>
            <div className="main-wrapper" >
                <section className="cta-section theme-bg-light py-5">
                    <div className="container text-center single-col-max-width">
                        <h2 className="heading mb-3">Online Resume</h2>
                        <a className="btn btn-primary"
                            href={RESUME_FILE_DOWNLOAD}
                            target="_blank"><i className="fas fa-file-pdf mr-2"></i>Download PDF Version</a>
                    </div>
                </section>
                <ResumeComponent />
            </div>
        </div>);
};
