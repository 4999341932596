import React from "react";

export const Overview = ({ }) => {
    return (<section className="overview-section p-3 p-lg-5">
        <div className="container">
            <h2 className="section-title font-weight-bold mb-3">What I do</h2>
            <div className="section-intro mb-5">I have more than <nobr id="yearsCoding"></nobr> years' experience
                building software for clients all over the world. Below is a quick overview of my main technical
                skill sets and technologies I use. Want to find out more about my experience? Check out my <a
                    href="resume">online resume</a> and <a href="portfolio">project portfolio</a>.</div>
            {/* <script>
					const calculateDateDiff = (diffDate) => {
						var ageDifMs = Date.now() - diffDate;
						// miliseconds from epoch
						var ageDate = new Date(ageDifMs);
						return Math.abs(ageDate.getUTCFullYear() - 1970);
					}
					document.getElementById("yearsCoding").innerHTML = calculateDateDiff(new Date("08/21/2010"));
				</script> */}
            <div className="row">
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-java"></i></div>
                        <h3 className="item-title">Java</h3>
                        <div className="item-desc">Java Frameworks such as undertow, glassfish, spring, etc has been my
                            early day web frameworks of choice. I have experience building scalable, testable, and
                            extendable API’s. The API’s I have developed
                            are documented extensively using Swagger or Postman.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-react mr-2"></i></div>
                        <h3 className="item-title">React &amp; React Native</h3>
                        <div className="item-desc">Many of today's modern projects have moved into this space for web
                            applications along with cross
                            platform mobile applications. I have professional and personally been involved in
                            building large and small scale projects with
                            these frameworks, both of which bring different challenges.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}

                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-node-js"></i></div>
                        <h3 className="item-title">Node.js</h3>
                        <div className="item-desc">Popularized by the ease of frontend to backend framework designs,
                            this has been a POC
                            framework of choice, and in terms of containerization, along with runtime SaaS
                            offerings, one of choice, not only
                            for speed of development, but in the SaaS position, often faster being a Runtime
                            language in terms of start time.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}

                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-android"> </i><i className="fab fa-apple"></i></div>
                        <h3 className="item-title">Android / IOS</h3>
                        <div className="item-desc">Having fully built an IOS and android app for a startup company
                            Socievo, and an app called Nightli in college.
                            I have gained much experience with android studio, the differences in the java framework
                            implmentation, gradle among other things. IOS
                            we primarly used Swift but also still had to use Objective C at the time for things. So
                            the experience has been wide spread. For some other projects
                            on top of this, have experienced use with kotlin on the android side.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fa fa-database"></i></div>
                        <h3 className="item-title">SQL & NoSQL</h3>
                        <div className="item-desc">MSSQL, Postgres, MySQL, SQL Lite, Mongo DB, DyanmoDB and many more.
                            Each database has its use cases and
                            I have experience using them all. Relationships are great, but sometimes NoSQL can be a
                            better solution for complicated data structures or as a cache layer.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-docker"></i></div>
                        <h3 className="item-title">Docker</h3>
                        <div className="item-desc">Containers are the future of software development. I have experience
                            containerizing new and old project and being able to leverage the ability to run code in
                            the same environment between development, testing, and production. Containers improve
                            developers efficiency.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-aws"></i> <i className="fab fa-microsoft"></i>
                        </div>
                        <h3 className="item-title">Cloud Services</h3>
                        <div className="item-desc">I have spent the past several years deploying applications on the AWS
                            ecosystem. Amazon offers mature solutions for every aspect of software infrastructure.
                            Using SaaS offerings along with pushing images
                            to ECR, and deployed scalable applications ECS. I have an efficient strategy for
                            selecting a balance between application and financial optimization. On top of this at
                            other companies and personal use, I have used Azure and GCP under the same expectations.
                        </div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
                <div className="item col-6 col-lg-3">
                    <div className="item-inner text-center">
                        <div className="item-icon"><i className="fab fa-jenkins"></i></div>
                        <h3 className="item-title">CI/CD</h3>
                        <div className="item-desc">From Jenkins for more personal use to SaaS based offerings by cloud
                            platforms for this type of thing. CI/CD is always important for scalable software
                            approaches among many environments. AWS codebuild to circle ci, some solutions fit
                            better than others but it is always important to have devops culture.</div>
                    </div>
                    {/* <!--//item-inner--> */}
                </div>
                {/* <!--//item--> */}
            </div>
            {/* <!--//row--> */}
            <div className="text-center py-3"><a href="services" className="btn btn-primary"><i
                className="fas fa-arrow-alt-circle-right mr-2"></i>Services &amp; Pricing

            </a></div>

        </div>
        {/* <!--//container--> */}
    </section>)
};