import React from "react";
import Helmet from "react-helmet";
import Site from "./Site";
import Header from "./Header";
import Content from "./Content";
import Footer from "./Footer";
import Router from "./Router";
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'bootstrap/dist/js/bootstrap.js';
import 'popper.js/dist/popper.min.js';

export default () => (
	<Site>
		<Helmet
			title="thekevshow webpage"
			meta={[
				{ name: "description", content: "thekevshow webpage" },
				{ name: "keywords", content: "thkevshow" },
			]}
			script={[
				// { src: "../../assets/fontawesome/js/all.js" },
				{ src: "https://use.fontawesome.com/releases/v5.11.2/js/all.js" },
				// <!-- Javascript -->
				// { src: "../../assets/plugins/bootstrap/js/bootstrap.min.js" },
				// { src: "/node_modules/bootstrap/dist/js/bootstrap.min.js" },
				// { src: "../../assets/plugins/jquery-3.4.1.min.js" },
				// { src: "../../assets/plugins/popper.min.js" },
				// { src: "/node_modules/popper.js/dist/popper.min.js" },
				// { src: "../../assets/plugins/owlcarousel/owl.carousel.min.js" },
				// { src: "/node_modules/owl.carousel/dist/owl.carousel.min.js" },

				// <!-- Dark Mode -->
				// converted as much as possible, if we need to activate some of these
				// look into the original source to figure out if additional things would be needed
				// { src: "../../assets/plugins/js-cookie.min.js" },
				// { src: "node_modules/js-cookie/dist/js-cookie.js" },
				// { src: "../../assets/js/testimonials.js" },
				// { src: "../../assets/js/dark-mode.js" },
			]}
			link={[
				// {
				// 	rel: "stylesheet",
				// 	href: "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
				// },
				{
					id: "theme-style",
					rel: "stylesheet",
					href: "../../assets/css/theme-3.css",
				},
				// <!-- Google Fonts -->
				{
					rel: "stylesheet",
					href: "https://fonts.googleapis.com/css?family=Roboto:300,400,500,700",
				},
				// <!-- Owl Stylesheets -->
				// {
				// 	rel: "stylesheet",
				// 	href: "../../assets/plugins/owlcarousel/assets/owl.carousel.min.css",
				// },
				{
					rel: "stylesheet",
					href: "/node_modules/owl.carousel/dist/assets/owl.carousel.min.css",
				},
				{
					rel: "stylesheet",
					href: "/node_modules/owl.carousel/dist/assets/owl.theme.default.min.css",
				},
				// {
				// 	rel: "stylesheet",
				// 	href: "../../assets/plugins/owlcarousel/assets/owl.theme.default.min.css",
				// },
			]}
		/>
		<Header />
		<Content>
			<ToastContainer
				position="top-center"
				autoClose={3000}
				closeOnClick
				pauseOnHover
				transition={Slide}
			/>
			<Router />
		</Content>
		<Footer />
	</Site>
);
