import React from "react";

export const About = ({ }) => {

    return (
        <section className="about-me-section p-3 p-lg-5 theme-bg-light">
            <div className="container">
                <div className="profile-teaser media flex-column flex-lg-row">

                    <div className="media-body">
                        <h2 className="name font-weight-bold mb-1">Kevin Kelly</h2>
                        <div className="tagline mb-3">CEO (Ucept LLC) / Engineer</div>
                        <div className="bio mb-4">I’ve been passionate about programming and entrepreneurship since I was
                            very young and I love to learn in general,
                            but especially when it comes to tech.
                            I believe in the learn by doing approach because I’ve always learned from mistakes. I have
                            Specialized in web development practices, and cloud solutions,
                            but the extent of my work goes beyond that and more. To Full stack web development with
                            Front end, and mobile related projects as well as embedded
                            technologies with drones and more.
                            {/* <!-- bio --> */}
                        </div>

                        <div className="mb-4">
                            <a className="btn btn-primary mr-2 mb-3" href="portfolio"><i
                                className="fas fa-arrow-alt-circle-right mr-2"></i><span
                                    className="d-none d-md-inline">View</span> Portfolio</a>
                            <a className="btn btn-secondary mb-3" href="resume"><i
                                className="fas fa-file-alt mr-2"></i><span className="d-none d-md-inline">View</span>
                                Resume</a>
                        </div>
                    </div>
                    {/* <!--//media-body--> */}
                    {/* <!-- <img className="profile-image mb-3 mb-lg-0 ml-lg-5 mr-md-0" src="https://itwebservices.it/firma/avatar.png" alt=""> --> */}
                </div>
            </div>
        </section>
    );
};
