import React from "react";
import GREENVIEWTEAM_PROJECT_IMAGE from '../../../../assets/images/project/greenviewteam-project-image.png';

export const GreenViewTeamProject = ({ }) => {
    return (<div className="isotope-item col-md-6 mb-5 frontend backend">
        <div className="card project-card">
            <div className="row no-gutters">
                <div className="col-lg-4 card-img-holder">
                    <img src={GREENVIEWTEAM_PROJECT_IMAGE} className="card-img" alt="image" />
                </div>
                <div className="col-lg-8">
                    <div className="card-body">
                        <h5 className="card-title"><a href="project" className="theme-link">GreenView Seasonal Service Site</a></h5>
                        <p className="card-text">Managed a site for allow a company to have clients detail out the service and statements for what seasonal operations they wanted.
                            Along with this tracking contract, documents and more with a web application originally support in PHP, and migrated database over to mysql at the time.</p>
                        <p className="card-text"><small className="text-muted">Client: Greenview</small></p>
                    </div>
                </div>
            </div>
            <div className="link-mask">
                <a className="link-mask-link" href="project"></a>
                <div className="link-mask-text">
                    <a className="btn btn-secondary" href="project">
                        <i className="fas fa-eye mr-2"></i>View Case Study
                    </a>
                </div>
            </div>
            {/* <!--//link-mask--> */}
        </div>
        {/* <!--//card--> */}
    </div>)
};