import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { About } from './components/About';
import { Overview } from './components/Overview';
import { Featured } from './components/Featured';

export default () => {
	const navigate = useNavigate();
	// const isMounted = useMounted();

	const [isLoading, setIsLoading] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	return (
		<div className="main-wrapper">

			<About />
			{/* <!--//about-me-section--> */}

			<Overview />
			{/* overview section */}

			{/* <!-- Testimonials Container -->
				<!-- <div className="container">
				<hr> -->
				<!-- data that goes here if want to be included is in testimonials file -->
				<!-- </div> --> 
			*/}

			{/* line break */}
			<div className="container">
				<hr />
			</div>

			<Featured />
			{/* <!--//featured-section--> */}

			{/* <!-- <div className="container">
				<hr>
				// latest-blog container backed up in panel latest-blog.html
				</div> --> 
			*/}

			{/* <footer className="footer text-center py-4">
			<small className="copyright">Powered By <b>thekevshow.com</b></small>
		</footer> */}

		</div>
		// {/* <!--//main-wrapper--> */}
	);
};
